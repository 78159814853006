import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import uspSection from "../components/uspSection"
import BlockContent from '../components/block-content'
import { FaPrint, FaUserShield, FaRegClock, FaShieldAlt, FaMapMarkerAlt } from "react-icons/fa"
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"
import UspSection from "../components/uspSection"
import Helmet from 'react-helmet'
import $ from 'jquery'
import PortableText from '@sanity/block-content-to-react'
import servicesBackground from "../images/servicesIconBg.jpg"
import servicesPlumbing from "../images/plumbing.svg"
import servicesElectrical from "../images/electrical.svg"
import servicesHVAC from "../images/hvac.svg"
import servicesRepairs from "../images/repairs.svg"
import CTA from "../components/cta"

function getUrlVars(){
  var vars = [], hash;
  if(typeof window !== 'undefined'){
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for(var i = 0; i < hashes.length; i++)
      {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
      }
  }
  return vars;
}
var city = getUrlVars()["city"];

if (city !== undefined){
  let cityDash = city;
  cityDash = cityDash.replace(/-/g, ' ');

    var cityplace = " in " + cityDash;
    var citytitle = cityDash+"'s";
} 

export const query = graphql`
    query pageQuery($slug: String) {
        sanityPages(slug: {current: {eq: $slug}}) {
            pagetitle
            heroheader
            _rawHerocopy
            pagetype{
                pagetype
            }
            slug {
                current
            }
            usp1{
                uspTitle
                uspText
                icon
            }
            usp2{
                uspTitle
                uspText
                icon
            }
            usp3{
                uspTitle
                uspText
                icon
            }
            _rawFirstcopy
            _rawServices
            coupon {
                title
                type
            }
            heroimage {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            serviceimage {
                asset {
                    fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                    }
                }
            }
    }
        sanityCompanyInfo {        
          companyname
          companyTagline
            primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
            tertiarycolor{
              hex
            }
            tertiaryaltcolor{
              hex
            }
        }
        allSanitySocial{
          edges{
            node{
              socialproofTitle
              socialproofNumber
              }
            }
          }
    }
`


const now = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const today = days[now.getDay()];

function printCoupon() {
    if(typeof window !== 'undefined'){
        window.print();
    }
  }
  function changeActive(){
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }


export default ({ data }) => (
 
  <Layout> 
  <Helmet>
      <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>
  </Helmet>
    <div className="popupForm"><Form /></div>


    <div className="row heroRow">
      <div className="leftSection">
          <div className="leftSectionText">
              <p className="tagline_marker" style={{color: data.sanityCompanyInfo.tertiarycolor.hex, textTransform: 'uppercase'}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline} {cityplace}</p>
              <span className="heroBlockTitle"><h2>{data.sanityPages.heroheader} {cityplace}</h2></span>
              <PortableText blocks={data.sanityPages._rawHerocopy} />
              <a href="#" onClick={changeActive} 
              style={{ backgroundColor: data.sanityCompanyInfo.accentcolor.hex, borderColor: data.sanityCompanyInfo.accentcolor.hex }}
              > Schedule Service Today</a>
          </div>
      </div>
      <div className="rightSection">
          <BackgroundImage 
          style={{height: "100%", backgroundSize: "cover"}}
          fluid={data.sanityPages.heroimage.asset.fluid}>
          </BackgroundImage>
      </div>
  </div>


  <div className="coupon_section" style={{backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex}}>
      <div className="container">
        <div className="couponMain">
          <div className="coupon_text">
            <p className="mobileTitle" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Take Advantage of our latest offer</p>
            <p className="coupon" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
              {data.sanityPages.coupon.title} <br className="coupon_break"/> <span className="mobileCouponType">{data.sanityPages.coupon.type}</span></p>
              <p className="coupon_disclaimer_mobile" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
            <a href="#" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}} onClick={printCoupon}>Claim Offer</a>
          </div>
        </div>
        <div className="couponClaim">
            <p className="coupon_disclaimer" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
          </div>
      </div>
    </div>




   {/*} <div className="container pageContent homepage">
          <div className="row">    
        <PortableText blocks={data.sanityPages._rawFirstcopy} />
      </div>
</div>*/}


    <div className="servicesIcons" style={{height: "100%", backgroundImage: "url(" + servicesBackground + ")"}} > 
      <div className="container pageContent homepage">
        <h2 style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>What We Do</h2>
        <div className="servicesIconsInner">
          <div className="servicesIconsInnerLeft">
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <div className="servicesIconsDetailInner">
                  <svg xmlns="http://www.w3.org/2000/svg"  width="67.554" height="67.554" viewBox="0 0 67.554 67.554">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_122" data-name="Rectangle 122" width="67.554" height="67.554" fill="#2a4a65"/>
                    </clipPath>
                  </defs>
                  <g id="Group_156" data-name="Group 156" clip-path="url(#clip-path)">
                    <path id="Path_317" data-name="Path 317" d="M155.065,40.673l-6.279-6.279L181.973,1.207a4.124,4.124,0,0,1,5.832,0l.447.447a4.124,4.124,0,0,1,0,5.832Z" transform="translate(-121.906 0)" fill="#7a9fbc"/>
                    <path id="Path_318" data-name="Path 318" d="M26.79,222.223a16.435,16.435,0,0,0,1.99-16.881l3.388-3.388a1.554,1.554,0,0,0,0-2.2L26.214,193.8a1.554,1.554,0,0,0-2.2,0l-3.388,3.388a16.435,16.435,0,0,0-16.881,1.99,2.363,2.363,0,0,0-3.056,3.585L23.2,225.279a2.363,2.363,0,0,0,3.585-3.056" transform="translate(0 -158.418)" fill="#2a4a65"/>
                  </g>
                </svg>
                  <h3>Plumbing</h3>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                </div>
              </div>
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <div className="servicesIconsDetailInner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="67.554" height="67.554" viewBox="0 0 67.554 67.554">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_123" data-name="Rectangle 123" width="67.554" height="67.554" fill="#7a9fbc"/>
                        </clipPath>
                      </defs>
                      <g id="Mask_Group_158" data-name="Mask Group 158" clip-path="url(#clip-path)">
                        <path id="Path_319" data-name="Path 319" d="M62.478,0H5.076A5.075,5.075,0,0,0,0,5.077v57.4a5.075,5.075,0,0,0,5.076,5.076h57.4a5.075,5.075,0,0,0,5.076-5.076V5.077A5.075,5.075,0,0,0,62.478,0m-28.7,61.747a27.97,27.97,0,1,1,27.97-27.97,27.97,27.97,0,0,1-27.97,27.97" transform="translate(0 -0.001)" fill="#7a9fbc"/>
                        <path id="Path_320" data-name="Path 320" d="M183.352,178.189a4.474,4.474,0,1,0,4.473,4.476,4.474,4.474,0,0,0-4.473-4.476" transform="translate(-149.42 -148.843)" fill="#2a4a65"/>
                        <path id="Path_321" data-name="Path 321" d="M162.553,77.44a8.067,8.067,0,0,1,4.84,1.6c15.22-20.223-2.044-16.93-2.044-16.93-15.2,1.587-12.113,10.863-8.019,17.237a8.066,8.066,0,0,1,5.223-1.911" transform="translate(-128.623 -51.718)" fill="#2a4a65"/>
                        <path id="Path_322" data-name="Path 322" d="M237.2,175.274a8.1,8.1,0,0,1-2.4,10.208c16.435,19.047,16.9,1.53,16.9,1.53,1.645-14.743-7.47-14.2-14.5-11.738" transform="translate(-196.128 -145.292)" fill="#2a4a65"/>
                        <path id="Path_323" data-name="Path 323" d="M77.9,209c-26,2.892-11.448,13.256-11.448,13.256,11.509,9.756,16.105,1.453,17.926-5.848A8.1,8.1,0,0,1,77.9,209" transform="translate(-52.114 -174.579)" fill="#2a4a65"/>
                      </g>
                    </svg>
                  <h3>HVAC</h3>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                </div>
              </div>
          </div>
          <div className="servicesIconsInnerRight">
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <div className="servicesIconsDetailInner">
                  <svg id="Group_232" data-name="Group 232" xmlns="http://www.w3.org/2000/svg" width="77.041" height="71.28" viewBox="0 0 77.041 71.28">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_121" data-name="Rectangle 121" width="77.041" height="71.28" fill="#e49b74"/>
                      </clipPath>
                    </defs>
                    <g id="Group_154" data-name="Group 154" clip-path="url(#clip-path)">
                      <path id="Path_309" data-name="Path 309" d="M29.128,20.744l-4.651-2.685a1.608,1.608,0,1,0-1.608,2.784l4.651,2.685a1.608,1.608,0,1,0,1.608-2.784" transform="translate(-17.141 -13.861)" fill="#2a4a65"/>
                      <path id="Path_310" data-name="Path 310" d="M8.585,101.809A1.608,1.608,0,0,0,6.978,100.2H1.608a1.608,1.608,0,0,0,0,3.215h5.37a1.608,1.608,0,0,0,1.608-1.608" transform="translate(0 -77.84)" fill="#2a4a65"/>
                      <path id="Path_311" data-name="Path 311" d="M27.52,170.743l-4.651,2.685a1.608,1.608,0,1,0,1.608,2.784l4.651-2.685a1.608,1.608,0,0,0-1.608-2.784" transform="translate(-17.141 -132.471)" fill="#2a4a65"/>
                      <path id="Path_312" data-name="Path 312" d="M288.708,20.744l4.651-2.685a1.608,1.608,0,1,1,1.608,2.784l-4.651,2.685a1.608,1.608,0,1,1-1.608-2.784" transform="translate(-223.654 -13.861)" fill="#2a4a65"/>
                      <path id="Path_313" data-name="Path 313" d="M306.749,101.809a1.608,1.608,0,0,1,1.608-1.608h5.37a1.608,1.608,0,0,1,0,3.215h-5.37a1.608,1.608,0,0,1-1.608-1.608" transform="translate(-238.293 -77.84)" fill="#2a4a65"/>
                      <path id="Path_314" data-name="Path 314" d="M290.316,170.743l4.651,2.685a1.608,1.608,0,1,1-1.608,2.784l-4.651-2.685a1.608,1.608,0,0,1,1.608-2.784" transform="translate(-223.654 -132.471)" fill="#2a4a65"/>
                      <rect id="Rectangle_120" data-name="Rectangle 120" width="21.743" height="3.713" transform="translate(27.781 59.145)" fill="#2a4a65"/>
                      <path id="Path_315" data-name="Path 315" d="M125.473,293.879h0a12.317,12.317,0,0,0,4.488,1.081c.195,1.11,2.529,1.987,5.395,1.987s5.2-.877,5.4-1.987a12.32,12.32,0,0,0,4.492-1.081l.985-3.384H124.488Z" transform="translate(-96.707 -225.667)" fill="#2a4a65"/>
                      <path id="Path_316" data-name="Path 316" d="M90.42,0A23.794,23.794,0,0,0,68.542,33.114a8.428,8.428,0,0,0,.4.887,25.18,25.18,0,0,0,1.229,2.253l.828,1.5c3.528,6.385,4.986,9.029,4.986,13.71,0,5.295,2.164,5.705,2.828,5.705a1.277,1.277,0,0,0,.131-.006H101.9a1.284,1.284,0,0,0,.131.006,2.188,2.188,0,0,0,1.724-.949c.732-.953,1.1-2.552,1.1-4.756,0-4.681,1.462-7.325,4.986-13.71l.818-1.485a24.368,24.368,0,0,0,1.229-2.256,7.524,7.524,0,0,0,.4-.887A23.8,23.8,0,0,0,90.42,0m7.41,33.48-5.554,5.554V51.486H88.565V39.033L83.01,33.479a1.857,1.857,0,1,1,2.628-2.624L90.42,35.64l4.786-4.786a1.856,1.856,0,1,1,2.624,2.624" transform="translate(-51.768 0.001)" fill="#7a9fbc"/>
                    </g>
                  </svg>

                    <h3>Electrical</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                </div>
              </div>
              <div className="servicesIconsDetail" style={{backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                <div className="servicesIconsDetailInner">
                <svg id="Group_233" data-name="Group 233" xmlns="http://www.w3.org/2000/svg" width="71.946" height="67.554" viewBox="0 0 71.946 67.554">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_161" data-name="Rectangle 161" width="71.946" height="67.554" fill="#7a9fbc"/>
                  </clipPath>
                </defs>
                <g id="Group_196" data-name="Group 196" clip-path="url(#clip-path)">
                  <path id="Path_359" data-name="Path 359" d="M67.538,12.372H54.791V6.789A6.8,6.8,0,0,0,48,0H23.944a6.8,6.8,0,0,0-6.789,6.789v5.583H4.408A4.407,4.407,0,0,0,0,16.78v9.08H24.813V24.314a4.865,4.865,0,0,1,4.86-4.86h12.6a4.865,4.865,0,0,1,4.86,4.86V25.86H71.946V16.78a4.407,4.407,0,0,0-4.408-4.408m-17.078,0H21.486V6.789a2.461,2.461,0,0,1,2.458-2.458H48A2.461,2.461,0,0,1,50.46,6.789Z" fill="#2a4a65"/>
                  <path id="Path_360" data-name="Path 360" d="M47.133,132.081v1.974a4.865,4.865,0,0,1-4.86,4.86h-12.6a4.865,4.865,0,0,1-4.86-4.86v-1.974H0v33.442a4.408,4.408,0,0,0,4.408,4.408h63.13a4.408,4.408,0,0,0,4.408-4.408V132.081Z" transform="translate(0 -102.376)" fill="#7a9fbc"/>
                  <path id="Path_361" data-name="Path 361" d="M142.51,105.493h-12.6a.59.59,0,0,0-.589.589v7.364a.59.59,0,0,0,.589.589h12.6a.59.59,0,0,0,.589-.589v-7.364a.59.59,0,0,0-.589-.589" transform="translate(-100.238 -81.768)" fill="#7a9fbc"/>
                </g>
              </svg>

                  <h3>Repairs</h3>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                </div>
              </div>
          </div>
        </div>
        <a href="/our-services" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex, color:data.sanityCompanyInfo.tertiaryaltcolor.hex }}>View Our Services</a>
      </div>
    </div>

    
    <div className="socialProof" style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex, color:"#fff" }}>
                <div className="container">
                    <div className="socialBlurb">
                        {data.allSanitySocial.edges.map(({ node: social }) => (
                    <div className="blurp">
                        <div className="socialInfo">
                        <h3>{social.socialproofNumber}</h3>
                        <p>{social.socialproofTitle}</p>
                        </div>
                    </div>
                        ))}
                    </div>
                </div>
            </div>   

    <div className="row servicesRow">
      <div className="leftSection">
        <BackgroundImage 
        style={{height: "100%"}}
        fluid={data.sanityPages.serviceimage.asset.fluid}>
        </BackgroundImage>
      </div>
      <div className="rightSection" style={{ backgroundColor: data.sanityCompanyInfo.primarycolor.hex }}>
        <span className="servicesBlockTitle"><h2 style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Why You Should Choose {data.sanityCompanyInfo.companyname}</h2></span>
        <p style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}><PortableText blocks={data.sanityPages._rawServices} /></p>
        <div className="ourServiceUSP" >
          <div className="three-columns">
            <div className="column column1">
                <i className={"fa fa-" + data.sanityPages.usp1.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp1.uspTitle}</h2>
            </div>
            <div className="column column2">
                <i className={"fa fa-" + data.sanityPages.usp2.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                    <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp2.uspTitle}</h2>
            </div>
            <div className="column column3">
                <i className={"fa fa-" + data.sanityPages.usp3.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                    <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp3.uspTitle}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
   {/*} <div className="container pageContent">
      <div className="row">
        <BlockContent blocks={data.sanityPages._rawSecondcopy} />
      </div>
</div>*/}

  <CTA />
  </Layout>
)